/**
 * @name          jQuery Boilerplate
 *
 * based on: http://jqueryboilerplate.com/
 */

;(function ($, window, document, undefined) {
    'use strict';

    var pluginName = 'availabilityCalendar',
        defaults = {
            dayCheckboxSelector: '.datetime:not(.datetime--is-not-selectable) input',
            daySelector: '.datetime:not(.datetime--is-not-selectable)',
            dayActiveClass: 'cal--active',
            overlaySelector: '.availability__calendar__overlay',
            overlayOpenSelector: '.availability__calendar__overlay__teaser',
            overlayCloseSelector: '.availability__calendar__overlay__content--cancel',
            teaseOverlayClass: 'availability__calendar__overlay--tease',
            openOverlayClass: 'availability__calendar__overlay--open',
            teaserLabelEditMoreClass: 'availability__calendar__overlay__teaser--edit-more',
            teaserLabelEditOneClass: 'availability__calendar__overlay__teaser--edit-one',
            teaserLabelEditNotPossibleClass: 'availability__calendar__overlay__teaser--edit-not-possible',
            teaserLabelExchangeOneClass: 'availability__calendar__overlay__teaser--exchange-one',
            teaserLabelExchangeMoreClass: 'availability__calendar__overlay__teaser--exchange-more',
            timesSelectFromSelector: '.availability__calendar__overlay__content__times__select--from',
            timesSelectToSelector: '.availability__calendar__overlay__content__times__select--to',
            lockedClass: 'cal--locked',
            formErrorClass: 'availability__calendar__overlay__content__times--sel--error',
            TrainerSelector: '.availability__calendar__showTrainerLevel',
            showTrainerSelector: '.availability__calendar__body',
            showTrainerActive: 'availability__calendar__body--showAllLevels',
        };

    // The actual plugin constructor
    function Plugin(element, options) {
        this.$element = $(element);
        this.options = $.extend({}, defaults, options);
        this.$trainerButton = this.$element.find(this.options.TrainerSelector);
        this.$levelSelect = this.$element.find(this.options.showTrainerSelector);
        this.$days = this.$element.find(this.options.dayCheckboxSelector);
        this.$overlay = this.$element.find(this.options.overlaySelector);
        this.$overlayTeaser = this.$element.find(this.options.overlayOpenSelector);
        this.$overlayCancelButton = this.$element.find(this.options.overlayCloseSelector);
        this.$timesSelectFrom = this.$element.find(this.options.timesSelectFromSelector);
        this.$timesSelectTo = this.$element.find(this.options.timesSelectToSelector);
        this.$form = this.$element.find('form');
        this.init();
    }

    // methods
    var methods = {
        init: function () {
            var self = this;
            self.initEventHandlers();
        },
        initEventHandlers: function () {
            var self = this;
            self.$trainerButton.on('click', function (e) {
                self.$levelSelect.toggleClass(self.options.showTrainerActive);
            });

            self.$days.on('click', function (e) {
                $(this).closest('label').parent().toggleClass(self.options.dayActiveClass);

                self.showTeaserIfAtLastOneDayIsSelected();
            });

            self.$overlayTeaser.on('click', function () {
                self.toggleOverlay();
            });

            self.$overlayCancelButton.on('click', function () {
                self.hideOverlay();
            });
            self.$timesSelectFrom.on('change', function () {
                var isDisabled = $(this).prop('selectedIndex') != 0;
                $(this).siblings(self.options.timesSelectToSelector).prop('disabled', isDisabled);
            });
            self.$timesSelectTo.on('change', function () {
                var isDisabled = $(this).prop('selectedIndex') != 0;
                $(this).siblings(self.options.timesSelectFromSelector).prop('disabled', isDisabled);
            });

            self.$form.on('submit', function (e) {
                self.$element.find('select').prop('disabled', false);
            });

        },
        showTeaserIfAtLastOneDayIsSelected: function () {
            var self = this;
            if (self.selectedLockedDays().length > 0 || self.selectedUnlockedDays().length > 0) {
                self.showOverlayTeaser();
            } else {
                self.hideOverlayTeaser();
            }
        },
        selectedUnlockedDays: function () {
            var self = this;
            var $selectedDays = self.$days.filter(':checked');
            var days = [];
            $.each($selectedDays, function (i, selectedDay) {
                if (!$(selectedDay).parents(self.options.daySelector).hasClass(self.options.lockedClass)) {
                    days.push($(selectedDay));
                }
            });

            return days;
        },
        selectedLockedDays: function () {
            var self = this;
            var $selectedDays = self.$days.filter(':checked');
            var days = [];
            $.each($selectedDays, function (i, selectedDay) {
                if ($(selectedDay).parents(self.options.daySelector).hasClass(self.options.lockedClass)) {
                    days.push($(selectedDay));
                }
            });

            return days;
        },
        showOverlayTeaser: function () {
            var self = this;
            self.$overlay.addClass(self.options.teaseOverlayClass);

            if (self.areLockedAndUnlockedDaysSelected()) {
                self.showEditNotPossibleTeaserLabel();
            } else {
                if (self.selectedLockedDays().length > 0) {
                    self.showExchangeTeaserLabel();
                } else {
                    self.showEditTeaserLabel();
                }
            }
        },
        hideOverlayTeaser: function () {
            var self = this;
            self.$overlay.removeClass(self.options.teaseOverlayClass);
        },
        hideOverlay: function () {
            var self = this;
            self.$overlay.removeClass(self.options.openOverlayClass);
        },
        toggleOverlay: function () {
            var self = this;
            self.$overlay.toggleClass(self.options.openOverlayClass);
            if (self.$overlay.hasClass(self.options.openOverlayClass)) {
                self.resetOverlayForm();
                self.setDateAndTimeInForm();
            }
        },
        areLockedAndUnlockedDaysSelected: function () {
            var self = this;
            return self.selectedLockedDays().length != 0 && self.selectedUnlockedDays().length != 0;

        },
        showEditNotPossibleTeaserLabel: function () {
            var self = this;
            self.hideAllTeaserLabels();
            self.$overlayTeaser.addClass(self.options.teaserLabelEditNotPossibleClass);
        },
        showEditTeaserLabel: function () {
            var self = this;
            self.hideAllTeaserLabels();
            if (self.selectedUnlockedDays().length == 1) {
                self.$overlayTeaser.addClass(self.options.teaserLabelEditOneClass);
            } else {
                self.$overlayTeaser.addClass(self.options.teaserLabelEditMoreClass);
            }
        },
        showExchangeTeaserLabel: function () {
            var self = this;
            self.hideAllTeaserLabels();
            if (self.selectedLockedDays().length == 1) {
                self.$overlayTeaser.addClass(self.options.teaserLabelExchangeOneClass);
            } else {
                self.$overlayTeaser.addClass(self.options.teaserLabelExchangeMoreClass);
            }
        },
        hideAllTeaserLabels: function () {
            var self = this;
            self.$overlayTeaser.removeClass(self.options.teaserLabelEditMoreClass);
            self.$overlayTeaser.removeClass(self.options.teaserLabelEditOneClass);
            self.$overlayTeaser.removeClass(self.options.teaserLabelEditNotPossibleClass);
            self.$overlayTeaser.removeClass(self.options.teaserLabelExchangeMoreClass);
            self.$overlayTeaser.removeClass(self.options.teaserLabelExchangeOneClass);
        },
        availabilityOfFirstSelectedDay: function () {
            var self = this;
            var $selectedUnlockedDays = self.selectedUnlockedDays();
//             var result = $selectedUnlockedDays.length == 1;
// //TODO: Vor-/Nachmittag Unterscheidung! Eigentlich alle werte....
//             if ($selectedUnlockedDays.length > 1) {
//                 var areAllDaysSameAvailability = true;
            var availabilityOfFirstSelectedDay = {};
            availabilityOfFirstSelectedDay.amAvailabilityType = $selectedUnlockedDays[0].data('daytime-am-availability-type');
            availabilityOfFirstSelectedDay.amBeginTime = $selectedUnlockedDays[0].data('daytime-am-begintime');
            availabilityOfFirstSelectedDay.amEndTime = $selectedUnlockedDays[0].data('daytime-am-endtime');
            availabilityOfFirstSelectedDay.pmAvailabilityType = $selectedUnlockedDays[0].data('daytime-pm-availability-type');
            availabilityOfFirstSelectedDay.pmBeginTime = $selectedUnlockedDays[0].data('daytime-pm-begintime');
            availabilityOfFirstSelectedDay.pmEndTime = $selectedUnlockedDays[0].data('daytime-pm-endtime');
            return availabilityOfFirstSelectedDay;
            //     $.each($selectedUnlockedDays, function (i, unlockedDay) {
            //         var $unlockedDay = $(unlockedDay);
            //         if (areAllDaysSameAvailability && (availabilityOfFirstSelectedDay.amAvailabilityType != $unlockedDay.data('daytime-am-availability-type')
            //             || availabilityOfFirstSelectedDay.amBeginTime != $unlockedDay.data('daytime-am-begintime')
            //             || availabilityOfFirstSelectedDay.amEndTime != $unlockedDay.data('daytime-am-endtime')
            //             || availabilityOfFirstSelectedDay.pmAvailabilityType != $unlockedDay.data('daytime-pm-availability-type')
            //             || availabilityOfFirstSelectedDay.pmBeginTime != $unlockedDay.data('daytime-pm-begintime')
            //             || availabilityOfFirstSelectedDay.pmEndTime != $unlockedDay.data('daytime-pm-endtime'))){
            //             areAllDaysSameAvailability = false;
            //         }
            //     });
            //     console.info(areAllDaysSameAvailability);
            //     return areAllDaysSameAvailability;
            // }else if($selectedUnlockedDays.length == 1){
            //     return true;
            // }
        },
        setDateAndTimeInForm: function () {
            var self = this;

            var $selectedUnlockedDays = self.selectedUnlockedDays();
            var availabilityOfFirstSelectedDay = self.availabilityOfFirstSelectedDay();

            if ($selectedUnlockedDays[0].data('daytime-am-availability-type') !== undefined) {
                self.setAmBeginTimeFormValue($selectedUnlockedDays, availabilityOfFirstSelectedDay);
                self.setAmEndTimeFormValue($selectedUnlockedDays, availabilityOfFirstSelectedDay);
            }
            if ($selectedUnlockedDays[0].data('daytime-pm-availability-type') !== undefined) {
                self.setPmBeginTimeFormValue($selectedUnlockedDays, availabilityOfFirstSelectedDay);
                self.setPmEndTimeFormValue($selectedUnlockedDays, availabilityOfFirstSelectedDay);
            }

            if ($selectedUnlockedDays[0].data('daytime-am-availability-type') !== undefined) {
                self.setAmAvailability($selectedUnlockedDays, availabilityOfFirstSelectedDay);
            }

            if ($selectedUnlockedDays[0].data('daytime-pm-availability-type') !== undefined) {
                self.setPmAvailability($selectedUnlockedDays, availabilityOfFirstSelectedDay);
            }
        },
        setAmAvailability: function ($selectedUnlockedDays, availabilityOfFirstSelectedDay) {
            var self = this;
            self.setFormCheckboxValue($selectedUnlockedDays, self.$element.find('input[name="daytimes[1][availability]"]'), availabilityOfFirstSelectedDay.amAvailabilityType, 'daytime-am-availability-type');
        },
        setAmBeginTimeFormValue: function ($selectedUnlockedDays, availabilityOfFirstSelectedDay) {
            var self = this;
            self.setFormSelectValue($selectedUnlockedDays, self.$timesSelectFrom.eq(0), availabilityOfFirstSelectedDay.amBeginTime, 'daytime-am-begintime');
        },
        setAmEndTimeFormValue: function ($selectedUnlockedDays, availabilityOfFirstSelectedDay) {
            var self = this;
            self.setFormSelectValue($selectedUnlockedDays, self.$timesSelectTo.eq(0), availabilityOfFirstSelectedDay.amEndTime, 'daytime-am-endtime');
        },
        setPmAvailability: function ($selectedUnlockedDays, availabilityOfFirstSelectedDay) {
            var self = this;
            self.setFormCheckboxValue($selectedUnlockedDays, self.$element.find('input[name="daytimes[2][availability]"]'), availabilityOfFirstSelectedDay.pmAvailabilityType, 'daytime-pm-availability-type');
        },
        setPmBeginTimeFormValue: function ($selectedUnlockedDays, availabilityOfFirstSelectedDay) {
            var self = this;
            self.setFormSelectValue($selectedUnlockedDays, self.$timesSelectFrom.eq(1), availabilityOfFirstSelectedDay.pmBeginTime, 'daytime-pm-begintime');
        },
        setPmEndTimeFormValue: function ($selectedUnlockedDays, availabilityOfFirstSelectedDay) {
            var self = this;
            self.setFormSelectValue($selectedUnlockedDays, self.$timesSelectTo.eq(1), availabilityOfFirstSelectedDay.pmEndTime, 'daytime-pm-endtime');
        },
        setFormSelectValue: function ($selectedUnlockedDays, $selectField, valueOfFirstSelectedDay, dataAttribute) {
            var self = this;
            var valueTheSame = true;
            $.each($selectedUnlockedDays, function (i, selectedDay) {
                if (valueTheSame && valueOfFirstSelectedDay != $(selectedDay).data(dataAttribute)) {
                    valueTheSame = false;
                }
            });

            if (valueTheSame) {
                $selectField.find('option[value="' + valueOfFirstSelectedDay + '"]').prop('selected', true);
            } else {
                $selectField.addClass(self.options.formErrorClass);
            }
        },
        setFormCheckboxValue: function ($selectedUnlockedDays, $checkboxFields, valueOfFirstSelectedDay, dataAttribute) {
            var self = this;
            var valueTheSame = true;
            $.each($selectedUnlockedDays, function (i, selectedDay) {
                if (valueTheSame && valueOfFirstSelectedDay != $(selectedDay).data(dataAttribute)) {
                    valueTheSame = false;
                }
            });

            if (valueTheSame) {
                $checkboxFields.filter('[value="' + valueOfFirstSelectedDay + '"]').prop('checked', true);
            } else {
                $checkboxFields.addClass(self.options.formErrorClass);
                $checkboxFields.prop('checked', false);
            }
        },
        resetOverlayForm: function () {
            var self = this;

            self.$form.find('input, select').removeClass(self.options.formErrorClass);
            self.$timesSelectFrom.eq(0).find('option').eq(0).prop('selected', true);
            self.$timesSelectFrom.eq(1).find('option').eq(0).prop('selected', true);

            self.$element.find('input[name="daytimes[1][availability]"][value="0"]').prop('checked', true);
            self.$element.find('input[name="daytimes[2][availability]"][value="0"]').prop('checked', true);
        }
    };

    // build
    $.extend(Plugin.prototype, methods);

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function (options) {
        this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
            }
        });

        return this;
    };

})(jQuery, window, document);
