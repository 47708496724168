/**
 * @name          jQuery Boilerplate
 *
 * based on: http://jqueryboilerplate.com/
 */

;(function ($, window, document, undefined) {
    'use strict';

    var pluginName = 'message',
        defaults = {
            closeSelector: '.messages__messagebox__icon'
        };

    // The actual plugin constructor
    function Plugin(element, options) {
        this.$element = $(element);
        this.options = $.extend({}, defaults, options);
        this.$closeButton = this.$element.find(this.options.closeSelector);
        this.init();
    }

    // methods
    var methods = {
        init: function () {
            var self = this;
            self.initEventHandlers();
        },
        initEventHandlers: function () {
            var self = this;
            self.$closeButton.on('click', function () {
                self.$element.fadeOut(300);
            });
        }

    };

    // build
    $.extend(Plugin.prototype, methods);

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function (options) {
        this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
            }
        });

        return this;
    };

})(jQuery, window, document);
