/**
 * @name          jQuery Boilerplate
 *
 * based on: http://jqueryboilerplate.com/
 */

;(function ($, window, document, undefined) {
    'use strict';

    var pluginName = 'ajaxlink',
        defaults = {};

    // The actual plugin constructor
    function Plugin(element, options) {
        this.$element = $(element);
        this.options = $.extend({}, defaults, options);
        this.init();
    }

    // methods
    var methods = {
        init: function () {
            var self = this;
            self.initEventHandlers();
        },
        initEventHandlers: function () {
            var self = this;
            $('body').on('click', 'a:not(a[data-no-ajax])', function (e) {
                e.preventDefault();
                var stateObj = {info: "neuer Chronik Eintrag"};
                history.pushState(stateObj, "", "/?page=" + $(this).attr('href').substr($(this).attr('href').lastIndexOf('/') + 1));

                self.loadPage($(this).attr('href'));
            });
        },
        loadPage: function (url) {
            var self = this;
            $.getJSON(url, function (result) {
               self.onAjaxComplete(result.data);
            });
        },
        onAjaxComplete: function (data) {
            var self = this;

            self.replaceHeader(data.header);
            self.replaceMessageView(data.messageView);
            self.replaceContent(data.content);
            self.replaceFooter(data.footer);

            self.scrollToTop();
            initPlugins();
        },
        replaceHeader: function (data) {
            var self = this;
            $('header').replaceWith(data);
        },
        replaceMessageView: function (data) {
            var self = this;
            $('.message-wrap').html(data);

        },
        replaceContent: function (data) {
            var self = this;
            $('.content-wrap').html(data);
        },
        replaceFooter: function (data) {
            var self = this;
            var $footer = $('footer');
            if ($footer.length > 0) {
                $footer.replaceWith(data);
            } else {
                $('#content').after(data);
            }
        },
        scrollToTop: function () {
            var self = this;

            $('body, html').animate({
                scrollTop: 0
            }, 0);
        }
    };

    // build
    $.extend(Plugin.prototype, methods);

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function (options) {
        this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
            }
        });

        return this;
    };

})(jQuery, window, document);
