/**
 * @name          jQuery Boilerplate
 *
 * based on: http://jqueryboilerplate.com/
 */

;(function ($, window, document, undefined) {
	'use strict';

	var pluginName = 'accordion',
		defaults = {
			headSelector : '.accordion--head',
			itemSelector : '.accordion--item',
			openClass : 'login__register__accordion__item--open'
		};

	// The actual plugin constructor
	function Plugin(element, options) {
		this.$element = $(element);
		this.options = $.extend({}, defaults, options);
		this.init();
	}

	// methods
	var methods = {
		init: function () {
			var self = this;
			self.initEventHandlers();
			
		},
		initEventHandlers: function () {
			var self = this;
			self.$element.find(self.options.headSelector).on('click', function () {
				$(self.options.itemSelector).toggleClass(self.options.openClass);

			});
			self.$element.find(self.options.headSelector).on('click', function () {
				$(self.options.itemSelector).scrollIntoView();
			});
		}
	};

	// build
	$.extend(Plugin.prototype, methods);

	// A really lightweight plugin wrapper around the constructor,
	// preventing against multiple instantiations
	$.fn[pluginName] = function (options) {
		this.each(function () {
			if (!$.data(this, 'plugin_' + pluginName)) {
				$.data(this, 'plugin_' + pluginName, new Plugin(this, options));
			}
		});

		return this;
	};

})(jQuery, window, document);
