$(document).ready(function () {

    $('body').ajaxlink();
    initPlugins();

});


function initPlugins(){
    $('body').ajaxlink();
    $('.accordion').accordion();
    $('.login__register').registerOverlay();
    $('.account__data__section').accountOverlay();
    $('.availability__calendar').availabilityCalendar();
    $('.messages__messagebox').message();

}